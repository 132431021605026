import "./App.css";
import React, { useEffect, useState } from "react";
import Router from "./router";
import Navbar from "./common/components/Navbar";
import ContactUs from "common/components/contactUs";
import Footer from "common/components/footer";
import Floating from "common/components/Floating";
import { BrowserRouter } from "react-router-dom";
import { getNotice } from "lib/Apis";
import { Modal } from "antd";
function App() {
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isModalMessage, setIsModalMessage] = useState<any>({});
  const [noticeType, setNoticeType] = useState<any>('');

  //降级公告
  useEffect(() => {
    getNotice({
      app: "ins_xubao",
      key: "keyguanwang",
      pin: "",
    }).then((res: any) => {
      if (res) {
        setIsModalOpen(res?.open);
        if (res.config) {
          setIsModalMessage(res?.config);
          setNoticeType(res?.config?.noticeType)
        }
      }
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="list_product">
          <Router />
        </div>
        <ContactUs />
        <Floating />
        <Footer />
      </BrowserRouter>
      <Modal
        className="toc-modal"
        title="系统公告"
        open={isModalOpen && noticeType ==='1'}
        closable={false}
        maskClosable={false}
        footer={[]}
      >
        <p style={{ fontWeight: 600, fontSize: "14px" }}>亲爱的用户：</p>
        <p>{isModalMessage ? isModalMessage.noticeText : ""}</p>
      </Modal>
    </div>
  );
}

export default App;
