import React, { Suspense, useEffect, useState } from 'react';
import './index.scss';
import ErrorBoundaryHOC from '../components/error-boundary'
import icon_complete from '@/static/imgs/order/icon_complete.svg'
import img_app_ssp from '@/static/imgs/order/img_app_ssp.jpg'
import img_app_mp from '@/static/imgs/code_jdalgzh.jpg'
import { useNavigate } from 'react-router-dom';
import { useUrlSearchParams } from '@/lib/useUrlSearchParams'
import { getDownloadPolicyUrl, getStoreyOrderDetail } from '@/lib/order/apis'
import ToastUtils from '@/lib/toast'
import InsLoading from '@/order-components/ins-loading'
import JDAZTraceUtils from '@/lib/order/jdaz_trace'
// import InsButton from '@/order-components/ins-button'
import { LINK_TO_BXSC} from "lib/utils";
const ErrorBoundaryFallback = React.lazy(() => import('@/order-components/error-boundary/fallback'));

interface IOrderInfo {
	mobile: string,
	policyNo: string,
	proposalNo: string,
	startDate: string,
	endDate: string,
	insuredName: string,
	showName: string,
	multiPolicy: boolean
}

const Index = () => {

	const navigate = useNavigate();

	const {ot, type} = useUrlSearchParams();

	const [showLoading, setShowLoading] = useState(false);
	const [orderInfo, setOrderInfo] = useState<IOrderInfo>(null);
	const [alert, setAlert] = useState('');

	// const [policyDownloadUrl, setPolicyDownloadUrl] = useState('');

	useEffect(() => {
		console.log('type', type);
		// document.title = '结果页';
		window.scrollTo(0, 0);
		if (ot) {
			setShowLoading(true);
			getStoreyOrderDetail(ot)
			.then((res: any) => {
				console.log('getStoreyOrderDetail', res);
				setShowLoading(false);
				convertData(res);
			})
			.catch((res) => {
				setShowLoading(false);
				setAlert(res?.err || '请求失败');
				// setOrderInfo({
				// 	mobile: '13212345678',
				// 	policyNo: 'P1234567123456123',
				// 	proposalNo: 'T1234567812345678',
				// 	startDate: '2024-05-13',
				// 	endDate: '2025-05-12',
				// 	insuredName: '哈哈哈',
				// 	showName: '京东安联保险'
				// })
			})
			// getDownloadPolicyUrl(ot)
			// .then((res: string) => {
			// 	setPolicyDownloadUrl(res);
			// })
		} else {
			setAlert('链接无效');
		}
	}, []);

	const convertData = (resData) => {
		const {proposer, insuredVoList, orderBaseVo} = resData;
		let insuredName = '';
		let insuredLength = insuredVoList.length;
		if (insuredLength === 1) {
			insuredName = insuredVoList[0].name;
		} else if (insuredLength > 10) {
			insuredName = `${insuredVoList[0].name},${insuredVoList[1].name}，${insuredVoList[2].name}，等共${insuredLength}人`;
		} else {
			let insuredNames = [];
			insuredVoList.forEach((item) => {
				insuredNames.push(item.name);
			})
			insuredName = insuredNames.join(',');
		}
		setOrderInfo({
			mobile: proposer.phone || '',
			policyNo: orderBaseVo.policyNo || '',
			proposalNo: orderBaseVo.proposalNo || '',
			startDate: orderBaseVo.policyEffectTime.split(' ')[0],
			endDate: orderBaseVo.policyExpiryTime.split(' ')[0],
			insuredName: insuredName,
			showName: orderBaseVo.showName || '京东安联保险',
			multiPolicy: orderBaseVo.multiPolicy
		})
		JDAZTraceUtils.resultStart(orderBaseVo.traceNoReplace);
	}

	const handleCopyPolicy = (value: string) => {
		try {
			// https才可用
			navigator.clipboard.writeText(value)
			.then(() => {
				ToastUtils.info('复制成功');
			})
			.catch(() => {
				ToastUtils.info('复制失败');
			})
		} catch (e) {
			console.error('navigator.clipboard.writeText', e);
			ToastUtils.info('复制失败');
		}
	}

	const handleGoBack = () => {
		// navigate('/bxsc', {replace: true});
		LINK_TO_BXSC()
	}

	// const handleDownloadPolicy = () => {
	// 	window.open(downloadPolicy(ot), '_blank');
	// }

	return (
		<div className='result-container'>

			<InsLoading show={showLoading} />

			{
				alert ?
					<Suspense fallback={<div />}>
						<ErrorBoundaryFallback alert={alert} />
					</Suspense>
					:
					<React.Fragment>
						{
							orderInfo &&
							<React.Fragment>
								<div className='v-result-header'>
									<img className='v-result-header_img' src={icon_complete} alt={''} />
									<span className='v-result-header_text'>
										{type === 'CertManualAuditWaiting' ? '您的投保请求已提交成功' : '恭喜您，投保成功！'}
									</span>
								</div>
								<div className='v-result-ctn'>
									<div className='v-title-tag' />
									<p className='v-text-prompt'>
										<span>
											{type === 'CertManualAuditWaiting' ? '因投保证件为非身份证，需要进行人工核保。' : '您可通过扫码下方二维码，登录京东安联客户服务小程序随时查询保单，也可以扫码关注京东安联保险公众号查看更多保险商品。'}
										</span>
										{
											type === 'CertManualAuditWaiting' &&
											<span className='v-text-alert'>{`核保结果将以“短信形式”发送至投保人手机号（${orderInfo.mobile}）上，请注意查收。`}</span>
										}
									</p>

									{
										type === 'CertManualAuditWaiting' ?
											<div className='v-manual-ctn'>
												<div className='v-manual-step'>
													<div className='v-manual-step-item'>
														<div className='v-step-step v-step-done'>1</div>
														<p className='v-step-text'>提交完成</p>
														<p className='v-step-desc' />
													</div>
													<div className='v-step-line-done' />
													<div className='v-manual-step-item'>
														<div className='v-step-step v-step-current'>2</div>
														<p className='v-step-text' style={{color: '#E1251B'}}>人工审核</p>
														<p className='v-step-desc'>预计 2 个工作日</p>
													</div>
													<div className='v-step-line-next' />
													<div className='v-manual-step-item'>
														<div className='v-step-step v-step-next'>3</div>
														<p className='v-step-text'>支付费用</p>
														<p className='v-step-desc'>{`手机号${orderInfo.mobile}将接收支付链接`}</p>
													</div>
												</div>
												<div className='v-policy'>
													<p className='v-row-item'>保障期间：{orderInfo.startDate} 至 {orderInfo.endDate}</p>
													{
														!orderInfo.multiPolicy &&
														<p className='v-row-item'>
															<span>投保单号：{orderInfo.proposalNo}</span>
															<span className='v-copy' onClick={() => handleCopyPolicy(orderInfo.proposalNo)}>复制</span>
														</p>
													}
													<p className='v-row-item'>被保人：{orderInfo.insuredName}</p>
												</div>
											</div>
											:
											<div className='v-ctn'>
												<div className='v-img-qrcode-wrap'>
													<img className='v-img-qrcode' src={img_app_mp} alt={''} />
													<span className='v-text-qrcode'>京东安联保险微信公众号</span>
												</div>
												<div className='v-img-qrcode-wrap'>
													<img className='v-img-qrcode' src={img_app_ssp} alt={''} />
													<span className='v-text-qrcode'>京东安联客户服务小程序</span>
												</div>
												<div className='v-policy'>
													<p className='v-row-item'>保障期间：{orderInfo.startDate} 至 {orderInfo.endDate}</p>
													{
														!orderInfo.multiPolicy &&
														<p className='v-row-item'>
															<span>保单号：{orderInfo.policyNo}</span>
															<span className='v-copy' onClick={() => handleCopyPolicy(orderInfo.policyNo)}>复制</span>
														</p>
													}
													<p className='v-row-item'>被保人：{orderInfo.insuredName}</p>
													{
														// policyDownloadUrl &&
														!orderInfo.multiPolicy &&
														<a className='v-download-policy' href={getDownloadPolicyUrl(ot)}>下载电子保单</a>
													}
													{/*{*/}
													{/*// policyDownloadUrl &&*/}
													{/*<InsButton*/}
													{/*className='v-download'*/}
													{/*value='下载电子保单'*/}
													{/*onClick={handleDownloadPolicy}*/}
													{/*/>*/}
													{/*}*/}
												</div>
											</div>
									}

									<div className='v-divider' />

									<p className='v-footer' onClick={handleGoBack}>返回商品列表页，继续投保</p>

								</div>

							</React.Fragment>
						}
					</React.Fragment>
			}

		</div>
	);
};

export default ErrorBoundaryHOC(Index);
