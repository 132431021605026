import React, { useEffect } from "react";
import "./index.scss";
import img_code1 from "static/imgs/code_jd_al_cxqjd.png";
import img_code2 from "static/imgs/code_jd_al_gfqjd2.png";
// import img_code3 from 'static/imgs/code_jdbxgzh.jpg';
import img_code3 from "static/imgs/code_jdalgzh.jpg";
import img_code4 from "static/imgs/code_jdbxxcx3.jpg";
import img_code5 from "static/imgs/code_jdxcx.png";
import img_code6 from "static/imgs/code_khfwxcx.jpg";
import img_code7 from "static/imgs/code_jdxcx_jdalkhfw.png";
import { Link } from "react-router-dom";

const CompanyProfile: React.FC = () => {
  useEffect(() => {
    document.title = "京东安联-互联网信息披露";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="company-info-wrap">
      <h2 className="title">互联网保险</h2>
      <div className="table_l_r">
        <div className="table_item">
          <div className="table_l_r_left">证件信息</div>
          <div className="table_l_r_right">
            <span>
              <a
                href="https://img03.az.jd.com/ins-user-m/develop/weapp/license_20241031.jpg"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                《营业执照》
              </a>
            </span>
            <span>
              <a
                href="https://img03.az.jd.com/ins-user-m/develop/website/pdf/保险许可证.pdf"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                《经营保险业务经营许可证》
              </a>
            </span>
          </div>
        </div>
        <div className="table_item">
          <div className="table_l_r_left">自营网络平台信息</div>
          <div className="table_l_r_right">
            <div className="line">
              <div className="label">名称：</div>
              <div className="value">京东安联财产保险有限公司</div>
            </div>
            <div className="line">
              <div className="label">网址：</div>
              <div className="value">
                <a
                  href="https://www.jdallianz.com"
                  target="_blank"
                  className="link"
                  rel="noopener noreferrer"
                >
                  www.jdallianz.com
                </a>
              </div>
            </div>
            <div className="line">
              <div className="label">微信公众号：</div>
              <div className="value">
                <span>
                  <label>京东安联保险</label>
                  <div className="hover_img">
                    <img src={img_code3} alt="" />
                  </div>
                </span>
              </div>
            </div>
            <div className="line">
              <div className="label">微信小程序：</div>
              <div className="value">
                <span>
                  <label>京东安联保险</label>
                  <div className="hover_img">
                    <img src={img_code4} alt="" />
                  </div>
                </span>
                <span>
                  <label>京东安联客户服务</label>
                  <div className="hover_img">
                    <img src={img_code6} alt="" />
                  </div>
                </span>
              </div>
            </div>
            <div className="line">
              <div className="label">京东小程序：</div>
              <div className="value">
                <span>
                  <label>京东安联保险</label>
                  <div className="hover_img">
                    <img src={img_code5} alt="" />
                  </div>
                </span>
                <span>
                  <label>京东安联客户服务</label>
                  <div className="hover_img">
                    <img src={img_code7} alt="" />
                  </div>
                </span>
              </div>
            </div>
            <div className="line">
              <div className="label">京东POP店：</div>
              <div className="value">
                <span>
                  <label>京东安联保险官方旗舰店</label>
                  <div className="hover_img">
                    <img src={img_code2} alt="" />
                  </div>
                </span>
                <span>
                  <label>京东安联车险官方旗舰店</label>
                  <div className="hover_img">
                    <img src={img_code1} alt="" />
                  </div>
                </span>
              </div>
            </div>
            <div className="line">
              <div className="label">TOB服务平台：</div>
              <div className="value">
                <a
                  href="https://partner.jdallianz.com"
                  target="_blank"
                  className="link"
                  rel="noopener noreferrer"
                >
                  京东安联企业团体平台
                </a>
              </div>
            </div>
            <div className="line">
              <div className="label">抖音号平台：</div>
              <div className="value">
                <span>抖音京东安联保险旗舰店</span>
                <span>抖音京东安联财产保险有限公司</span>
                <span>抖音京东安联财产保险北京分公司</span>
                <span>抖音京东安联财产保险有限公司北京分公司</span>
              </div>
            </div>
          </div>
        </div>

        <div className="table_item">
          <div className="table_l_r_left">中国保险行业协会披露地址</div>
          <div className="table_l_r_right">
            <span>
              <a
                href="http://icidp.iachina.cn/"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                http://icidp.iachina.cn/
              </a>
            </span>
          </div>
        </div>

        <div className="table_item">
          <div className="table_l_r_left">监管评价信息</div>
          <div className="table_l_r_right">
            <div>
            综合偿付能力充足率：2024年4季度190%；2024年3季度192%；2024年2季度186%；2024年1季度185%；2023年4季度191%；2023年3季度187%。            </div>
            <div>
            风险综合评级：2024年3季度A级；2024年2季度A级；2024年1季度BB级；2023年4季度BB级；2023年3季度BB级；2023年2季度B级。
            </div>
            <div>2023年度消费者权益保护监管评价：二级B。</div>
          </div>
        </div>

        <div className="table_item">
          <div className="table_l_r_left">互联网业务信息清单</div>
          <div className="table_l_r_right">
            <span>
              {/* <a
                href="https://img03.az.jd.com/ins-user-m/develop/website/pdf/合作保险中介机构及网络平台清单0831.pdf"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                合作保险中介机构网络平台清单
              </a> */}
              <Link to="/cooperative-insurance-list" className="link">
                合作保险中介机构网络平台清单
              </Link>
            </span>
            <span>
              <Link to="/internet-prod-list" className="link">
                互联网保险产品名称及产品信息
              </Link>
            </span>
          </div>
        </div>

        <div className="table_item">
          <div className="table_l_r_left">
            互联网保险产品及保单的查询和验真途径
          </div>
          <div className="table_l_r_right">
            <div className="delay_block">
              <h3 className="sub_title">保单查询：</h3>
              <span>
                <Link
                  to="/query"
                  className="link"
                  state={{ path: "query/carPolicy" }}
                  rel="noopener noreferrer"
                >
                  车险保单查询
                </Link>
              </span>
              <span>
                <Link
                  to="/query"
                  className="link"
                  state={{ path: "query/policy" }}
                  rel="noopener noreferrer"
                >
                  非车险保单查询
                </Link>
              </span>
            </div>
            <div>
              <h3 className="sub_title">理赔查询：</h3>
              <span>
                <Link
                  to="/query"
                  className="link"
                  state={{ path: "query/carClaim" }}
                  rel="noopener noreferrer"
                >
                  车险理赔查询
                </Link>
              </span>
              <span>
                <Link
                  to="/query"
                  className="link"
                  state={{ path: "query/claim" }}
                  rel="noopener noreferrer"
                >
                  非车险理赔查询
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="table_item">
          <div className="table_l_r_left">理赔申请流程</div>
          <div className="table_l_r_right">
            <div className="delay_block">
              <h3 className="sub_title">线上申请：</h3>
              <p>
                1.微信公众号“京东安联保险” &gt; 自助服务 &gt; 理赔服务 &gt;
                我要理赔（需登录）
              </p>
              <p>
                2.微信小程序“京东安联客户服务” &gt; 理赔服务 &gt;
                我要理赔（需登录）
              </p>
            </div>
            <div className="delay_block">
              <h3 className="sub_title">线下理赔：</h3>
              <p>拨打全国理赔服务热线950610</p>
            </div>
          </div>
        </div>
        <div className="table_item">
          <div className="table_l_r_left">省级分支机构和落地服务机构信息</div>
          <div className="table_l_r_right">
            <span style={{ lineHeight: "44px" }}>
              <Link
                to="/public-info/base-info/company-profile#section1"
                state="scroll_10"
                className="link"
              >
                https://www.jdallianz.com/public-info/base-info/company-profile
              </Link>
            </span>
          </div>
        </div>

        <div className="table_item">
          <div className="table_l_r_left">客户服务及消费者投诉电话</div>
          <div className="table_l_r_right">
            <div className="delay_block">
              <h3 className="sub_title">24小时客服电话：</h3>
              <p>境内服务热线： 950610</p>
              <p>境外服务热线： +86 527 8090 0000</p>
            </div>
            <div className="delay_block">
              <h3 className="sub_title">官方在线服务网址：</h3>
              <p>官方微信号：JDInsure</p>
              <p>微信号名称：京东安联保险</p>
              <p>微信小程序：京东安联客户服务、京东安联保险</p>
            </div>
            <div>
              <h3 className="sub_title">假冒网站投诉通道：</h3>
              <p>
                如您发现假冒我司网站、假冒我司互联网应用程序等与互联网保险业务相关的违法犯罪活动时，可通过以下邮箱进行举报，我们在接收的第一时间及时处理。
              </p>
              <p>
                举报邮箱：
                <a href="mailto:Contact@allianz.cn" className="link">
                  Contact@allianz.cn
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="table_item">
          <div className="table_l_r_left">理赔争议处理流程</div>
          <div className="table_l_r_right">
            <div>
              理赔争议处理机制参照投诉处理流程执行。如双方协商无法达成共识，消费者可采取申请核查、调解、仲裁、诉讼等途径解决。如有调解需求，可联系当地保险纠纷调解组织申请调解或请分支机构服务人员协助，联系方式详见公开信息披露。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
