import React, { useState } from "react";
import "./index.scss";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import jdAppCode from "static/imgs/code_jd_al_gfqjd2.png";
import wxCode from "static/imgs/code_jdalgzh.jpg";
import { LINK_MODE} from "../../../lib/utils";

const infoDisclosure = [
  {
    name: "常见问题",
    href: `/suggestion/qa`,
  },
  {
    name: "意见与反馈",
    href: `/suggestion/feedback`,
  },
  {
    name: "投诉处理流程",
    href: `/suggestion/complaint`,
  },
  {
    name: "消费者权益保护与金融教育",
    href: `/safety/xb/02`,
  },
  {
    name: "总经理接待日",
    href: `/open-day`,
  },
  {
    name: "资料下载",
    href: `/download-info/ty`,
  },
];

const linkList = [
  {
    name: "安联集团",
    href: "https://www.allianz.com/en.html",
  },
  {
    name: "京东商城",
    href: "https://www.jd.com/",
  },
  {
    name: '安联控股',
    href: 'https://www.allianzholding.cn/home',
  },
  {
    name: "安联世合",
    href: "https://www.allianz-partners.com/zh_CN.html",
  },
  {
    name: "安联全球企业及特殊风险",
    href: "https://www.agcs.allianz.com/",
  },
  {
    name: "安联人寿",
    href: "https://www.allianz.com.cn/",
  },
  {
    name: "安联投资",
    href: "https://cn.allianzgi.com/zh-cn",
  },
  {
    name: "安联再保险",
    href: "https://www.allianzre.com/en_GB.html",
  },
  {
    name: "安联贸易",
    href: "https://www.allianz-trade.cn/",
  },
  {
    name: "国家金融监督管理总局",
    href: "http://www.cbirc.gov.cn/cn/view/pages/index/index.html",
  },
  {
    name: "广东正和消保中心",
    href: "https://szxb.gd-zhenghe.com.cn",
  },
  {
    name: "金融产品查询平台",
    href: "https://www.jrcpcx.cn/"
  }
];

const ContactUs: React.FC = () => {
  const [isShow, setIsShow] = useState(false);
  const [wxIsShow, setWxIsShow] = useState(false);
  const handleToChange = (name: any) => {
    if (name === "jd") {
      setIsShow(true);
    } else {
      setWxIsShow(true);
    }
  };

  return (
    <div className="contact_main">
      <div className="contact_top"></div>
      <div className="contact_center">
        <div className="contact">
          <div className="contactUs info_jdinsurance">
            <div className="contact_tit">关注我们</div>
            <div className="code_insurance">
              <div
                className={classNames("code", wxIsShow ? "mask_yayer" : "")}
                onMouseEnter={() => {
                  handleToChange("jd");
                }}
                onMouseLeave={() => {
                  setIsShow(false);
                  setWxIsShow(false);
                }}
              >
                <div className="info_code">
                  <img src={jdAppCode} alt="" />
                </div>
                <div className="store">京东APP官方旗舰店</div>
              </div>

              <div
                className={classNames("code", isShow ? "mask_yayer" : "")}
                onMouseEnter={() => {
                  handleToChange("wx");
                }}
                onMouseLeave={() => {
                  setIsShow(false);
                  setWxIsShow(false);
                }}
              >
                <div className="info_code">
                  <img src={wxCode} alt="" />
                </div>
                <div className="store">京东安联保险微信公众号</div>
              </div>
            </div>
          </div>
          <div className="info_line"></div>
          <div className="contactUs info_contact">
            <div className="contact_tit">联系我们</div>
            <div className="contact_info">
              <p>
                <a href={LINK_MODE + "/about-us/comp-info"} rel="noreferrer">
                  公司介绍
                </a>
              </p>
              <p>
                <a href={LINK_MODE + "/about-us/contact-us"} rel="noreferrer">
                  联系我们
                </a>
              </p>
              <div className="phone">全国统一服务热线：</div>
              <div className="phone">
                <span>950610</span>
              </div>
              <p>
                商务合作：
                <a
                  href="mailto:Digital@Allianz.cn"
                  rel="noreferrer"
                  className="link"
                >
                  Digital@Allianz.cn
                </a>
              </p>
              <div className="phone">
                <a
                  // target='_blank'
                  // href='https://img03.az.jd.com/ins-user-m/develop/website/pdf/2023年健康险增值服务招标公告20230912.pdf'
                  href="/bit-document"
                  rel="noopener noreferrer"
                >
                  招标公告
                </a>
              </div>
            </div>
          </div>
          <div className="info_line"></div>
          <div className="contactUs  ">
            <div className="contact_tit">友情链接</div>
            <div className="contact_info flex-wrap">
              {linkList.map((item, index) => (
                <p key={index}>
                  <a href={item.href} target="_blank" rel="noreferrer">
                    {item.name}
                  </a>
                </p>
              ))}
            </div>
          </div>
          <div className="info_line"></div>
          <div className="contactUs " style={{ width: "210px" }}>
            <div className="contact_tit">客户服务</div>
            <div className="contact_info">
              {infoDisclosure.map((item, index) => (
                <p key={index}>
                  <NavLink to={item.href}>{item.name}</NavLink>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
